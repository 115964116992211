import React from "react"
import { graphql, Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import PortfolioLayout from "../components/portfolioLayout"
import Layout from '../components/layout'

export default function Portfolio({ location, data }) {
  
  const post = data.markdownRemark;

  const handleClick = (e) => {
      if(e.target.className === 'modal__scroll'){
        navigate("/portfolio", { replace: true, state: { keepScroll: (location.state && location.state.keepScroll) } });
      }
  };

  return (location.state && location.state.isModal ?

    (<PortfolioLayout>
      
      <Helmet>
        <title>{post.frontmatter.title} - Portfolio</title>
        <meta name="description" content="" />
        <body className="no-overflow" />
      </Helmet>

        <div className="modal__shadow" onClick={handleClick}>
            <div className="modal__scroll">
                <div className="modal" role="dialog" aria-labelledby="modal-header" aria-describedby="modal-content" aria-modal="true">
                    <h1 id="modal-header">{post.frontmatter.title}</h1>
                    <ul className="modal__tags">
                      {post.frontmatter.tags.map((tag, i) => (
                        <li key={i}>{ tag }</li>
                      ))}
                    </ul>
                    <img src={post.frontmatter.img.publicURL} alt="" />
                    <div className="modal__content" id="modal-content" dangerouslySetInnerHTML={{ __html: post.html }} />
                    <Link to="/portfolio" state={{ isModal: true, keepScroll: true }} replace className="modal__close"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="#575955" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg></Link>
                </div>
            </div>
        </div>
      
      </PortfolioLayout>)

    : (

      <Layout>

      <Helmet>
        <title>{post.frontmatter.title} - Portfolio</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="portfolio-item">
          <h1>{post.frontmatter.title}</h1>
          <ul className="portfolio-item__tags">
            { post.frontmatter.tags.map((tag, i) => (
              <li key={i}>{ tag }</li>
            ))}
          </ul>
          <img src={post.frontmatter.img.publicURL} alt="" />
          <div className="portfolio-item__content" dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>

    </Layout>

    )
  
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
        img {
          publicURL
          }
      }
    }
  }
`